<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('thesis_and_project_coordinator_approve_title')"
                        :isFilter="false"></Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('thesis_and_project_coordinator_approve_title')"
                              :isFilter="false"></HeaderMobile>
            </template>
            <div class="row data-form">
                <div class="col-12 col-md-12">
                    <span>{{$t('thesis_and_project')}}</span>
                    <b-tabs content-class="py-5 position-relative" class="mt-5" v-model="tabIndex">
                        <div class="tabs-bottom-line"></div>
                        <b-tab active>
                            <template #title><i class="ri-checkbox-blank-circle-line top-plus-2 mr-2"></i>{{ $t('waiters_for_approve') }}</template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <b-table striped hover :items="waiters" :fields="fields" show-empty>
                                        <template #cell(step_name)="row">
                                            <span>{{row.item.step_name}}</span>
                                        </template>
                                        <template #cell(button)="data">
                                            <b-button v-if="checkPermission('thesisandproject_show')" @click="showDetail(data.item.id)" variant="primary" size="sm">{{$t('show')}}</b-button>
                                        </template>
                                        <template #empty="scope">
                                            <h6 class="text-center text-success">{{ $t('no_result') }}</h6>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-checkbox-circle-fill top-plus-2 mr-2"></i>{{ $t('waiters_approved') }}</template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <b-table striped hover :items="approved" :fields="fieldsApproved" show-empty>
                                        <template #cell(button)="data">
                                            <b-button v-if="checkPermission('thesisandproject_update')" @click="cancelApplication(data.item.id)" variant="danger" size="sm">{{$t('do_cancel')}}</b-button>
                                        </template>
                                        <template #empty="scope">
                                            <h6 class="text-center">{{ $t('no_result') }}</h6>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                        <b-tab>
                            <template #title><i class="ri-checkbox-blank-circle-fill top-plus-2 mr-2"></i>{{ $t('waiters_rejected') }}</template>
                            <div class="row">
                                <div class="col-12 col-md-12">
                                    <b-table striped hover :items="rejected" :fields="fieldsRejected" show-empty>
                                        <template #cell(button)="data">
                                            <b-button v-if="checkPermission('thesisandproject_update')" @click="cancelApplication(data.item.id)" variant="danger" size="sm">{{$t('do_cancel')}}</b-button>
                                        </template>
                                        <template #empty="scope">
                                            <h6 class="text-center">{{ $t('no_result') }}</h6>
                                        </template>
                                    </b-table>
                                </div>
                            </div>
                        </b-tab>
                    </b-tabs>
                </div>
                <CommonModal size="xl" ref="detailModal">
                    <template v-slot:CommonModalTitle>
                        <div>{{$t('thesis_and_project_advisor_approve_title')}}</div>
                    </template>
                    <template v-slot:CommonModalContent>
                        <div>
                            <advisor-approve-detail
                                :metaTitle="$t('thesis_and_project_coordinator_approve_title')"
                                :id="selectedId" @hide="closeDetailModal"></advisor-approve-detail>
                        </div>
                    </template>
                </CommonModal>
            </div>
        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header";
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile";

import ThesisAndProjectService from "@/services/ThesisAndProjectService";
import qs from "qs";
import moment from "moment";
import CommonModal from "@/components/elements/CommonModal";
import AdvisorApproveDetail from "./AdvisorApproveDetail";
import ApprovalStepService from "../../../services/ApprovalStepService";

export default {
    components: {
        AppLayout,
        Header,
        HeaderMobile,

        CommonModal,
        AdvisorApproveDetail
    },
    data() {
        return {
            tabIndex: 1,
            waiters: [],
            approved: [],
            rejected: [],
            selectedId: 0,
            fields : [
                {
                    key: 'button',
                    label: ''
                },
                {
                    key: 'period',
                    label: this.$t('period')
                },
                {
                    key: 'application_date',
                    label: this.$t('application_date')
                },
                {
                    key: 'title',
                    label: this.$t('title')
                },
                {
                    key: 'advisor',
                    label: this.$t('advisor')
                },
                {
                    key: 'student',
                    label: this.$t('student')
                },
                {
                    key: 'student_status',
                    label: this.$t('student_status')
                },
                {
                    key: 'period_status',
                    label: this.$t('period_status')
                },
                {
                    key: 'step_name',
                    label: this.$t('approve_step')
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],

            fieldsApproved : [
                {
                    key: 'button',
                    label: ''
                },
                {
                    key: 'student_number',
                    label: this.$t('student_number')
                },
                {
                    key: 'student',
                    label: this.$t('student')+' '+this.$t('name_surname')
                },
                {
                    key: 'title',
                    label: this.$t('title')
                },
                {
                    key: 'application_date',
                    label: this.$t('application_date')
                },
                {
                    key: 'approval_status',
                    label: this.$t('approval_status')
                },
                {
                    key: 'last_update_date',
                    label: this.$t('last_update_date')
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ],

            fieldsRejected: [
                {
                    key: 'button',
                    label: ''
                },
                {
                    key: 'student_number',
                    label: this.$t('student_number')
                },
                {
                    key: 'student',
                    label: this.$t('student')+' '+this.$t('name_surname')
                },
                {
                    key: 'title',
                    label: this.$t('title')
                },
                {
                    key: 'application_date',
                    label: this.$t('application_date')
                },
                {
                    key: 'approval_status',
                    label: this.$t('approval_status')
                },
                {
                    key: 'last_update_date',
                    label: this.$t('last_update_date')
                },
                {
                    key: 'id',
                    label: '',
                    thClass: 'd-none',
                    tdClass: 'd-none'
                }
            ]
        }
    },
    metaInfo() {
        return {
            title: this.$t("thesis_and_project_coordinator_approve_title")
        }
    },
    methods: {
        getData(approveStatus){
            const config = {
                params: {
                    filter: {coordinator_status: approveStatus},
                    limit: '-1',
                    sort: 'id'
                },
                paramsSerializer: (params) => qs.stringify(params, {encode: false}),
            };

            ThesisAndProjectService.getCoordinatorApplications(config).then(response => {
                if(approveStatus == 'pending'){
                    this.waiters = []
                    response.data.data.forEach((item) => {
                        let period_txt = item.semester == 1 ? this.$t('fall') : this.$t('spring')
                        this.waiters.push({
                            period: item.academic_year+' '+period_txt,
                            application_date: moment(item.created_at).format('DD.MM.YYYY'),
                            title: item.title,
                            advisor: item.advisor.name+' '+item.advisor.surname,
                            student: item.student.name+' '+item.student.surname+' - '+item.student.student_number,
                            student_status: item.student.student_status_name,
                            period_status: item.student.semester_status_name,
                            step_name: item.step_name,
                            id: item.id
                        })
                    })
                } else if(approveStatus == 'approved'){
                    this.approved = []
                    response.data.data.forEach((item) => {
                        this.approved.push({
                            student_number: item.student.student_number,
                            student: item.student.name+' '+item.student.surname,
                            title: item.title,
                            application_date: moment(item.created_at).format('DD.MM.YYYY'),
                            approval_status: this.approvalStatusText(item.approve_status),
                            last_update_date: null,
                            step_name: item.step_name,
                            id: item.id
                        })
                    })
                } else if(approveStatus == 'rejected'){
                    this.rejected = []
                    response.data.data.forEach((item) => {
                        this.rejected.push({
                            student_number: item.student.student_number,
                            student: item.student.name+' '+item.student.surname,
                            title: item.title,
                            application_date: moment(item.created_at).format('DD.MM.YYYY'),
                            approval_status: this.approvalStatusText(item.approve_status),
                            last_update_date: null,
                            step_name: item.step_name,
                            id: item.id
                        })
                    })
                }
            })
        },

        showDetail(Id){
            this.selectedId = Id
            this.$refs.detailModal.$refs.commonModal.show()
        },

        closeDetailModal(){
            this.$refs.detailModal.$refs.commonModal.hide()
            this.getData('pending')
        },

        cancelApplication(Id){
            this.$swal({
                title: this.$t('attention'),
                text: this.$t('approve_or_reject_confirm'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            }).then(response => {
                if (response.isConfirmed == true) {
                    ThesisAndProjectService.show(Id).then(response => {
                        let data = response.data.data
                        ApprovalStepService.restore(data.active_step.id).then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                            this.getData('approved')
                        })
                    }).catch(e => {
                        this.$toast.error(e.data.message)
                    })
                }
            })
        },

        approvalStatusText(text){
            if(text == 'approved'){
                return this.$t('approved')
            } else if(text == 'pending'){
                return this.$t('pending')
            } else {
                return this.$t('rejected')
            }
        }
    },

    watch: {
        tabIndex: {
            handler: function (val) {
                if(val == 0){
                    this.getData('pending')
                } else if(val == 1){
                    this.getData('approved')
                } else if(val == 2){
                    this.getData('rejected')
                }
            }
        }
    },

    created() {

    }

};
</script>
